import(/* webpackMode: "eager" */ "/home/runner/work/lmbr-ameno/lmbr-ameno/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_babel-plugin_4y2kb5cngxqdyoxmfw3igxw4le/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lmbr-ameno/lmbr-ameno/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_babel-plugin_4y2kb5cngxqdyoxmfw3igxw4le/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lmbr-ameno/lmbr-ameno/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_babel-plugin_4y2kb5cngxqdyoxmfw3igxw4le/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lmbr-ameno/lmbr-ameno/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_babel-plugin_4y2kb5cngxqdyoxmfw3igxw4le/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lmbr-ameno/lmbr-ameno/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_babel-plugin_4y2kb5cngxqdyoxmfw3igxw4le/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lmbr-ameno/lmbr-ameno/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_babel-plugin_4y2kb5cngxqdyoxmfw3igxw4le/node_modules/next/dist/client/components/render-from-template-context.js");
